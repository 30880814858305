import React from 'react'

export const StreamIcon = () => (
  <svg width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6 8.5C6.02464 8.49993 6.04905 8.50474 6.07183 8.51414C6.09461 8.52354 6.1153 8.53735 6.13273 8.55477C6.15015 8.5722 6.16396 8.59289 6.17336 8.61567C6.18276 8.63845 6.18757 8.66286 6.1875 8.6875"
      stroke="#525252"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.81398 8.6895C5.81371 8.66483 5.81832 8.64036 5.82753 8.61748C5.83674 8.5946 5.85037 8.57376 5.86765 8.55615C5.88493 8.53855 5.90551 8.52453 5.92822 8.51489C5.95093 8.50526 5.97531 8.5002 5.99998 8.5"
      stroke="#525252"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6 8.87695C5.95027 8.87695 5.90258 8.8572 5.86742 8.82204C5.83225 8.78687 5.8125 8.73918 5.8125 8.68945"
      stroke="#525252"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.18896 8.68945C6.18903 8.71409 6.18423 8.73851 6.17483 8.76128C6.16543 8.78406 6.15162 8.80476 6.13419 8.82218C6.11677 8.83961 6.09607 8.85341 6.0733 8.86281C6.05052 8.87221 6.02611 8.87702 6.00146 8.87695"
      stroke="#525252"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.35938 7.11735C4.84756 6.79801 5.41827 6.62793 6.00163 6.62793C6.58498 6.62793 7.15569 6.79801 7.64388 7.11735"
      stroke="#525252"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.55574 5.93163C7.80522 5.41272 6.91443 5.13477 6.00199 5.13477C5.08955 5.13477 4.19876 5.41272 3.44824 5.93163"
      stroke="#525252"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.5 5.87695C10.5 9.82695 7.034 12.0425 6.1855 12.527C6.12893 12.5593 6.0649 12.5763 5.99975 12.5763C5.9346 12.5763 5.87057 12.5593 5.814 12.527C4.9655 12.042 1.5 9.82595 1.5 5.87695C1.5 4.68348 1.97411 3.53889 2.81802 2.69497C3.66193 1.85106 4.80653 1.37695 6 1.37695C7.19347 1.37695 8.33807 1.85106 9.18198 2.69497C10.0259 3.53889 10.5 4.68348 10.5 5.87695Z"
      stroke="#525252"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export const NodeIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14.7158 16.125H9.46582V7.125C9.46582 6.92609 9.54484 6.73532 9.68549 6.59467C9.82614 6.45402 10.0169 6.375 10.2158 6.375H13.9658C14.1647 6.375 14.3555 6.45402 14.4962 6.59467C14.6368 6.73532 14.7158 6.92609 14.7158 7.125V16.125Z"
      stroke="#525252"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M11.7158 6.375V16.125" stroke="#525252" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M14.7158 8.625H11.7158"
      stroke="#525252"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.7158 10.875H11.7158"
      stroke="#525252"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.2158 13.6875C13.1787 13.6875 13.1425 13.6985 13.1117 13.7191C13.0808 13.7397 13.0568 13.769 13.0426 13.8032C13.0284 13.8375 13.0247 13.8752 13.0319 13.9116C13.0392 13.948 13.057 13.9814 13.0832 14.0076C13.1095 14.0338 13.1429 14.0517 13.1792 14.0589C13.2156 14.0661 13.2533 14.0624 13.2876 14.0482C13.3218 14.034 13.3511 14.01 13.3717 13.9792C13.3923 13.9483 13.4033 13.9121 13.4033 13.875C13.4033 13.8253 13.3836 13.7776 13.3484 13.7424C13.3132 13.7073 13.2655 13.6875 13.2158 13.6875V13.6875Z"
      stroke="#525252"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.0502 16.5395C15.9879 16.415 15.8922 16.3103 15.7738 16.2371C15.6554 16.1639 15.5189 16.1251 15.3797 16.125H8.80469C8.60578 16.125 8.41501 16.204 8.27436 16.3447C8.13371 16.4853 8.05469 16.6761 8.05469 16.875C8.05469 17.0739 8.13371 17.2647 8.27436 17.4053C8.41501 17.546 8.60578 17.625 8.80469 17.625H15.3777C15.5057 17.6253 15.6316 17.5928 15.7435 17.5307C15.8554 17.4685 15.9496 17.3788 16.017 17.27C16.0844 17.1612 16.1228 17.0369 16.1286 16.9091C16.1345 16.7812 16.1074 16.654 16.0502 16.5395V16.5395Z"
      stroke="#525252"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export const LocationIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M17.9997 11.15V8.83009C17.9998 8.67017 17.9519 8.51392 17.8622 8.3815C17.7726 8.24907 17.6453 8.14655 17.4968 8.08717L14.2969 6.8072C14.1062 6.73093 13.8935 6.73093 13.7028 6.8072L10.297 8.16984C10.1063 8.2461 9.89356 8.2461 9.70286 8.16984L6.54879 6.908C6.48794 6.88361 6.42202 6.87455 6.35684 6.88162C6.29166 6.8887 6.22922 6.91168 6.17501 6.94856C6.1208 6.98543 6.07649 7.03507 6.04597 7.0931C6.01546 7.15112 5.99967 7.21576 6.00001 7.28132V14.9468C5.99998 15.1067 6.04788 15.2629 6.13752 15.3953C6.22717 15.5278 6.35445 15.6303 6.50293 15.6897L9.70286 16.9696C9.89356 17.0459 10.1063 17.0459 10.297 16.9696L11.8335 16.3547"
      stroke="#525252"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M10 8.22656V17.0264" stroke="#525252" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M14 6.75V10.7499" stroke="#525252" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M15.5996 14.5898C15.6527 14.5898 15.7035 14.6109 15.741 14.6484C15.7785 14.6859 15.7996 14.7368 15.7996 14.7898"
      stroke="#525252"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.3999 14.7898C15.3999 14.7368 15.421 14.6859 15.4585 14.6484C15.496 14.6109 15.5469 14.5898 15.5999 14.5898"
      stroke="#525252"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.5999 14.99C15.5469 14.99 15.496 14.969 15.4585 14.9315C15.421 14.894 15.3999 14.8431 15.3999 14.79"
      stroke="#525252"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.7996 14.7891C15.7996 14.8421 15.7785 14.893 15.741 14.9305C15.7035 14.968 15.6527 14.9891 15.5996 14.9891"
      stroke="#525252"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.5997 12.3896C16.2362 12.3896 16.8466 12.6425 17.2967 13.0926C17.7468 13.5427 17.9996 14.1531 17.9996 14.7896C17.9996 15.8141 16.566 17.7634 15.9148 18.5964C15.8774 18.6443 15.8296 18.6831 15.775 18.7097C15.7204 18.7363 15.6604 18.7502 15.5997 18.7502C15.5389 18.7502 15.4789 18.7363 15.4243 18.7097C15.3697 18.6831 15.3219 18.6443 15.2845 18.5964C14.6333 17.7639 13.1997 15.8141 13.1997 14.7896C13.1997 14.1531 13.4526 13.5427 13.9026 13.0926C14.3527 12.6425 14.9631 12.3896 15.5997 12.3896Z"
      stroke="#525252"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
